/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';
@tailwind base;
@tailwind components;
@tailwind utilities;
// @import 'swiper/scss';
// @import 'swiper/scss/autoplay';
// @import 'swiper/scss/pagination';

@import "~swiper/css/bundle";

/* Custom CLL fonts */
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/RobotoCondensed-Regular_1.ttf");
}

@font-face {
  font-family: "BebasNeue";
  src: url("./assets/fonts/BebasNeue-Regular.ttf");
}

// @font-face {
//   font-family: 'broken-detroit';
//   src: url('./assets/fonts/Broken-Detroit.ttf');
// }

ion-content {
  font-family: "Roboto";
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background: white !important;
}

// ion-menu {
//     max-width: 280px !important;
// }

button {
  border-top-style: inherit !important;
  border-right-style: inherit !important;
  border-bottom-style: inherit !important;
  border-left-style: inherit !important;
}

ion-content {
  // --background: var(--ion-color-background-content) !important;
  --padding-top: var(--ion-safe-area-top) !important;
  --padding-bottom: var(--ion-safe-area-bottom) !important;
}

.content-without-padding {
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
}

/* Custom CLL colors */
.text-cll-danger {
  color: var(--ion-color-danger);
}

.text-cll-success {
  color: var(--ion-color-success);
}

.bg-cll-danger {
  background-color: var(--ion-color-danger);
}

.bg-button-inactive {
  background-color: #dd5d77;
}

.first_bg {
  // background-color: #2d2d2d;
  background-image: url("/assets/backgrounds/mapbackground.png");
  background-size: cover, contain;
  background-repeat: no-repeat;
}
.second_bg {
  background-image: url("/assets/backgrounds/second_bg.JPG");
  background-size: cover, contain;
  background-repeat: no-repeat;
}
:host {
  --width: auto !important;
}
.datetime-calendar > :host {
  --width: auto !important;
}

.booking-popover {
  @media only screen and (min-width: 280px) {
    --min-width: 350px;
  }

  @media only screen and (min-width: 768px) {
    --min-width: 500px;
  }
}
.profile-chat-popover{
  --min-height: 300px;
  --max-height: 300px;
  // bottom: 50px;
  --max-width: 250px;
  --min-width: 250px;
  --right: 20px;
  ion-content{
    background: #fff !important;
    --background: #fff !important;
    min-height: 300px;
    max-height: 300px;
  }

}
.user-profile-mobile{
  .fc-daygrid-event {
    .fc-event-title{
      display: none !important;
    }
  }
  .fc-daygrid-event-dot{
    margin: 0 auto;
  }
}
.user-main-container{
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
    min-height: auto !important;
  }
  .fc .fc-scroller-liquid-absolute{
    overflow: hidden auto !important;
  }
  .fc .fc-scroller {
    overflow: hidden auto !important;
  }
  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0 !important;
  }
  .fc-daygrid-day-events {
    .fc-daygrid-event-harness:not(:first-child){
      display: none !important;
    }
    .fc-daygrid-event-harness:first-child{
      background-color: transparent;
      a{
        cursor: pointer;
        .fc-event-title{
          display: none;
        }
        :hover{
          background-color: transparent !important;
        }
        .fc-daygrid-event-dot{
          margin: 0 auto;
        }
      }
    }
  }
}
.gallery_view_container{
  .swiper-button-next, .swiper-button-prev{
    color: rgb(63 131 248 / 1) !important;
  }
}

*{
  font-weight: normal;
}

.book-now-popover {
  --border-radius: 8px;
  @media only screen and (min-width: 280px) {
    --min-width: 300px;
  }

  @media only screen and (min-width: 959px) {
    --min-width: 900px;
  }
}

.book-now-popover::part(content) {
  border: none !important;
}

.booking-popover::part(content) {
  border: none !important;
}

.event-popover {
  --min-width: 300px;
}

.event-popover::part(content) {
  border: none !important;
}
.video-detail-popover{
  --max-width: 400px;
  --width: 100%;
  margin: 0 20px 0 20px;
}
ion-searchbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
 .searchbar-input-container {
     /* inner input styles here */
    input{
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      color: #fff !important;
      background-color: #f02a4b !important;
    }
    input.searchbar-input.sc-ion-searchbar-ios::placeholder{
      color: #fff !important;
      opacity: 0.88;
    }
    ion-icon{
      color: #fff !important;
    }
 }
}
@media only screen and (max-width: 767px) {
  .video-detail-popover{
    margin: 0 !important;
  }
}

.events_view_container {
  --width: 950px;
}

.age-check-popover {
  @media only screen and (min-width: 280px) {
    --min-width: 340px;
  }

  @media only screen and (min-width: 768px) {
    --min-width: 460px;
  }
}

.age-check-popover::part(backdrop) {
  opacity: 0.25 !important;
}

.border-cll-danger {
  border-color: #ee2f53 !important;
  border-width: 0.094rem !important;
}
